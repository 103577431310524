<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="bac">
    <div class="user_card">
      <div class="user_card_top">
        <div class="tx"></div>
        <div class="info">
        <p>用户1234</p>
          <p>完善账号信息</p>
          <button>立即完善</button>
        </div>
      </div>
      <div class="user_card_bottom">
        <div class="real-name">
          <i>i</i>
          <p>尊敬的用户，请完成实名认证，得到高质量服务</p>
        </div>
        <div class="number">
          <p>参与项目</p>
          <div class="number_1">0</div>
          <p>发布项目</p>
          <div class="number_2">0</div>
        </div>
      </div>
    </div>
    <div class="listBac">
      <div class="info_card_top">
        <div>
          <a>发布的项目</a>
          <i></i>
        </div>
        <div style="margin-right: 5.7rem;">
          <a>已完结</a>
          <i></i>
        </div>
      </div>
      <div class="info_card_medium">
        <div>提交日期</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
        <div>项目进度</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
          <div>项目操作</div>
      </div>
      <div class="list">
        <img src="../../../../images/resources.png" alt="" v-show="false">
        <div v-for="(item,i) in this.Info">
          <div class="bottomShow">
            <img :src="item.pic" alt="" @click="jump(item)">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Publish",
  data(){
    return{
      Info:'',
      subform:{
        currentPage:1,
        pageSize:12,
      }
    }
  },
  mounted() {
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/getPublishedProjects',
        data: this.subform
      }).then(res => {
        this.Info = res.data.data;
      })
    },
    jump(item){
      if (item.currentSchedule === 'confirmWinbid'){
        this.$router.push({path:'/BidDetermine',query: {projectId: item.id}})
      }else if (item.currentSchedule === 'signAgreement'){
        this.$router.push({path:'/CompanyContract',query: {projectId: item.id}})
      }else if (item.currentSchedule === 'service'){
        this.$router.push({path:'/CheckFiles',query: {projectId: item.id}})
      }else if (item.currentSchedule === 'taskRegistration'){
        this.$router.push({path:'/WinBidResult',query: {projectId: item.id}})
      }
    }
  }
}
</script>

<style scoped>
.bac{
  width: 19.2rem;
  padding-left: 2.98rem;
  padding-top: .5rem;
  background-color: #f5f5f5;

}

.user_card{
  width: 14.5rem;
  height: 2.6rem;
  background-color: #ffffff;
  margin-left: .7rem;
  margin-bottom: .35rem;
}

.user_card_top{
  width: 14.5rem;
  height: 1.9rem;
  padding-top: .42rem;
  padding-left: .65rem;
  border-bottom: .01rem solid #acaeab;
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.user_card_top .tx{
  float: left;
  width: 1.02rem;
  height: 1.02rem;
  margin-right: .2rem;
  border-radius: 50%;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
}

.user_card_top .info{
  float: left;
  width: 1.34rem;
  height: 1.02rem;
}

.user_card_top .info p:nth-of-type(1){
  /* float: left; */
  font-size: .21rem;
  color: #212121;
  margin-bottom: .1rem;
}

.user_card_top .info p:nth-of-type(1)::after{
  content: url("../../../../images/认证.png");
  width: .21rem;
  height: .21rem;
  margin-left: .05rem;
}

.user_card_top .info p:nth-of-type(2){
  float: left;
  font-size: .12rem;
  color: #acaeab;
  margin-bottom: .15rem;

}

.user_card_top .info button{
  width: .9rem;
  height: .3rem;
  background-color: #ff645f;
  color: #ffffff;
  font-size: .12rem;
}

.user_card_bottom{
  width: 14.5rem;
  height: .45rem;
  background-color: #ffffff;
}

.user_card_bottom .real-name{
  float: left;
  height: .18rem;
  /* margin-right: 6rem; */
  margin-left: .5rem;
  border: .01rem solid transparent;
  line-height: .18rem;
}

.user_card_bottom .real-name i{
  display: block;
  float: left;
  width: .12rem;
  height: .12rem;
  line-height: .1rem;
  margin-right: .1rem;
  border-radius: 50%;
  background-color: #ec414d;
  text-align: center;
  color: #ffffff;
  margin-top: .03rem;
}


.user_card_bottom .real-name p{
  float: left;
  font-size: .14rem;
  color:#acaeab;
}

.user_card_bottom .number{
  float: right;
  height: .18rem;
}

.user_card_bottom .number p{
  float: left;
  font-size: .16rem;
  color: #212121;
  margin-right: .38rem;
}

.user_card_bottom .number div{
  float: left;
  font-size: .16rem;
  color: #ec414d;
  margin-right: .9rem;
}

.listBac{
  width: 14.6rem;
  height: 6.1rem;
  margin-left: .7rem;
  background-color: #ffffff;
}

.listBac .info_card_top{
  /* width: 12.3rem; */
  height: 1rem;
  background-color: #ffffff;
  margin-left: .5rem;
  border: .01rem solid transparent;
  border-bottom: .02rem solid #acaeab;
  margin-bottom: .15rem;
  padding-left: .5rem;

}

.listBac .info_card_top div{
  float: left;
  width: 1.2rem;
  height: 1rem;
  line-height: 1rem;
  font-size: .21rem;
  font-weight: 600;
  color: black;
  margin-right: .3rem;
  box-sizing: border-box;
  padding-top: .05rem;
}

.listBac .info_card_top div i{
  display: block;
  width: 0rem;
  height: .04rem;
  background-color: #ec414d;
  transition: all 0.7s;
}

.listBac .info_card_top a{
  display: block;
  width: 100%;
  height: .94rem;

  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /*font-size: .2rem;*/
  color: #212121;
}

.listBac .info_card_top div:hover i{
  width: 100%;
}

.listBac .info_card_top input{
  width: 2.2rem;
  height: .35rem;
  color: #dcdcdc;
  margin-top: .4rem;
  margin-right: .17rem;
  padding-left: .1rem;
  border: .01rem solid #dcdcdc;
  border-radius: .05rem;
  font-size: .14rem;
}

.listBac .info_card_top button{
  width: 1rem;
  height: .35rem;
  margin-top: .4rem;
  font-size: .16rem;
  background-color: #ec414d;
  color: #ffffff;
  border-radius: .05rem;
}

.listBac .info_card_medium{
  width: 12.3rem;
  height: .45rem;
  padding-left: 1rem;
  background-color: #fdf9f8;
  margin-left: .5rem;
  margin-bottom: .45rem;
}

.listBac .info_card_medium div{
  float: left;
  /* width: .65rem; */
  height: .18rem;
  font-size: .16rem;
  margin-top: .1rem;
  /*margin-top: .1rem;*/
  margin-right: .1rem;
}

.listBac .info_card_medium i{
  display: block;
  float: left;
  margin-right: .9rem;
  margin-top: .11rem;
}

.listBac .list{
  width: 12.3rem;
  height: 3.95rem;
  margin-left: .5rem;
  padding-left: .5rem;
  background-size: 10% auto;
  overflow-y: auto;
}

.listBac .list div{
  float: left;
  width: 2.73rem;
  height: 1.9rem;
  margin-right: .15rem;
  margin-bottom: .2rem;
  background-image: url("../../../../images/background_work1.png");
  background-size: 100% 100%;
}

.listBac .list div:nth-of-type(3){
  background-image: url("../../../../images/background_work2.png");
}

.bottomShow{
  width: 2.73rem;
  height: 1.9rem;
  cursor: pointer;
}

.bottomShow img{
  width: 100%;
  height: 100%;
  transition: 0.5s all;
}

.bottomShow img:hover{
  width: 102%;
  height: 102%;
}


</style>